/* Define how SqPaymentForm iframes should look */
.sq-input {
  height: 38px;
  box-sizing: border-box;
  border: 1px solid #e0e2e3;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: white;
  border-radius: 6px;
  display: inline-block;
  -webkit-transition: border-color 0.2s ease-in-out;
  -moz-transition: border-color 0.2s ease-in-out;
  -ms-transition: border-color 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out;
  margin-bottom: 10px;
  margin-top: 0.25rem;
}

.sq-input input {
  padding: 0;
}

/* Define how SqPaymentForm iframes should look when they have focus */
.sq-input--focus {
  border: 2px solid rgba(99, 102, 241, 1);
}

/* Define how SqPaymentForm iframes should look when they contain invalid values */
.sq-input--error {
  border: 2px solid #e02f2f;
}

.sq-label {
  color: rgba(55, 65, 81, 1);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-transform: none;
}

.checkmark {
  display: inline-block;
  width: 30px;
  height: 14px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.checkmark_stem {
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: #35c603;
  left: 11px;
  top: 6px;
}

.checkmark_kick {
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: #35c603;
  left: 8px;
  top: 12px;
}
