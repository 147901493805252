@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* TODO: Integrate adobe fonts and set main font - https://trello.com/c/4itn12ro/33-integrate-adobe-fonts-and-set-main-font */
  h1,
  h2,
  h3 {
    @apply font-semibold text-mmj-header;
  }
  h1 {
    @apply text-2xl;
  }

  label {
    @apply font-semibold text-mmj-header;
  }

  input,
  select,
  textarea {
    @apply border-gray-300 rounded-lg text-mmj-header;
  }

  td {
    @apply px-6 py-4;
  }
}

@layer utilities {
  .shadow-mmj-lg {
    box-shadow: 15px 50px 100px rgba(47, 68, 164, 0.125883);
  }
}

html,
body,
#root {
  height: 100%;
}

button:disabled {
  @apply opacity-50 cursor-not-allowed;
}

.button {
  @apply py-2 px-6 font-bold rounded-full bg-mmj-gray;
}
.button-primary {
  @apply text-white bg-mmj-blue;
}

.button-danger {
  @apply text-white bg-red-600;
}

.button-outline {
  @apply border border-white bg-transparent;
}

.button-secondary {
  @apply border border-black bg-white text-black;
}

.button-link {
  @apply bg-transparent text-mmj-blue p-0
}

.sq-payment-form {
  width: 100% !important;
}
