.Input input,
.Input textarea,
.Input select {
  width: 100%;
}
.width-100 {
  width: 100%;
}
.nonresize{
  resize: none;
}